import React from 'react';

const Live = ({ width = 16, height = 16, color = '#000' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={width} height={height}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M14.2222 0H1.77778C1.30628 0 0.854097 0.210714 0.520699 0.585786C0.187301 0.960859 0 1.46957 0 2V14C0 14.5304 0.187301 15.0391 0.520699 15.4142C0.854097 15.7893 1.30628 16 1.77778 16H5.33333V14H1.77778V4H14.2222V14H10.6667V16H14.2222C15.2 16 16 15.1 16 14V2C16 1.46957 15.8127 0.960859 15.4793 0.585786C15.1459 0.210714 14.6937 0 14.2222 0V0ZM8 6L4.44444 10H7.11111V16H8.88889V10H11.5556L8 6Z"
    />
  </svg>
);

export default Live;
