import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Card, TitleWrap } from 'components/common';
import Github from 'components/common/Icons/Github';
import Live from 'components/common/Icons/Live';
import ProjectList from './config';

import { Wrapper, Grid, Item, Content, Stats, Languages, ProjectImage } from './styles';

export const Projects = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <React.Fragment>
      {ProjectList.map(group => (
        <Wrapper as={Container} id="projects" key={group.technology}>
          <h2>{group.technology}</h2>
          <Grid>
            {group.projects.map(project => (
              <Item theme={theme} key={project.id}>
                <Card
                  theme={theme}
                  key={project.id}
                  href={project.links.liveDeployment ? project.links.liveDeployment : project.links.githubRepo}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <Content
                    key={project.id}
                    href={project.links.liveDeployment ? project.links.liveDeployment : project.links.githubRepo}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <h4>{project.name}</h4>
                    <p>{project.description}</p>
                    <ProjectImage src={project.image} alt={project.name} />
                  </Content>
                  <TitleWrap>
                    <Stats theme={theme}>
                      <div>
                        {project.links.githubRepo ? (
                          <a key={project.id} href={project.links.githubRepo} target="_blank" rel="noopener noreferrer">
                            <Github color={theme === 'light' ? '#000' : '#fff'} />
                            <span></span>
                          </a>
                        ) : (
                          ''
                        )}
                      </div>
                      <div>
                        {project.links.liveDeployment ? (
                          <a
                            key={project.id}
                            href={project.links.liveDeployment}
                            target="_blank"
                            rel="noopener noreferrer"
                          >
                            <Live color={theme === 'light' ? '#000' : '#fff'} />
                            <span></span>
                          </a>
                        ) : (
                          ''
                        )}
                      </div>
                      <div></div>
                    </Stats>
                    <Stats theme={theme}>
                      <Languages>
                        {project.languages.map(({ id, name }) => (
                          <span key={id}>{name}</span>
                        ))}
                      </Languages>
                    </Stats>
                  </TitleWrap>
                </Card>
              </Item>
            ))}
          </Grid>
        </Wrapper>
      ))}
    </React.Fragment>
  );
};
