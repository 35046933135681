import tenxEvolve from 'assets/project-images/10xEvolve.png';
import missBeauty from 'assets/project-images/missBeautySpa.png';
import mockedIn from 'assets/project-images/MockedIn.png';
import UHost from 'assets/project-images/UHost.png';
import skulls from 'assets/project-images/skullsBones.png';
import expenses from 'assets/project-images/expensesTracker.png';
import guessANumber from 'assets/project-images/guessANumber.png';
import givng from 'assets/project-images/givng.png';
import ionv from 'assets/project-images/ionv.png';
import upcycle from 'assets/project-images/upcycle.png';
import shawn from 'assets/project-images/shawn.png';
import wow from 'assets/project-images/wow.png';
import luster from 'assets/project-images/luster.png';

const ProjectList = [
  {
    technology: 'Software Development',
    projects: [
      {
        id: '1',
        name: '10xEvolve Webpage',
        image: tenxEvolve,
        description: 'React and Typescript frontend, GCP deployment, NodeJS and Firestore backend.',
        links: {
          githubRepo: false,
          liveDeployment: 'https://10xevolve.com/',
        },
        languages: [
          { id: 'React', name: 'React' },
          { id: 'TypeScript', name: 'TypeScript' },
          { id: 'Node', name: 'Node.JS' },
          { id: 'GCP', name: 'GCP' },
          { id: 'SASS', name: 'SASS' },
        ],
      },
      {
        id: '2',
        name: 'Miss Beauty Spa',
        image: missBeauty,
        description: 'A Website/CMS built with the MERN stack. Frontend deployed on Firebase and backend on Heroku.',
        links: {
          githubRepo: false,
          liveDeployment: 'https://www.missbeautyspa.com.au/',
        },
        languages: [
          { id: 'Mongo', name: 'MongoDB' },
          { id: 'Express', name: 'Express' },
          { id: 'React', name: 'React' },
          { id: 'Node', name: 'Node.JS' },
          { id: 'Firebase', name: 'Firebase' },
          { id: 'Heroku', name: 'Heroku' },
        ],
      },
      {
        id: '3',
        name: 'MockedIn',
        image: mockedIn,
        description:
          'A mock version of LinkedIn used by people who want to update their LinkedIn profile, but they need some review from their HR/Career coach first.',
        links: {
          githubRepo: 'https://github.com/gabrielcon6/mockedin',
          liveDeployment: false,
        },
        languages: [
          { id: 'Mongo', name: 'MongoDB' },
          { id: 'Express', name: 'Express' },
          { id: 'React', name: 'React' },
          { id: 'Node', name: 'Node.JS' },
          { id: 'Firebase', name: 'Firebase' },
          { id: 'Heroku', name: 'Heroku' },
          { id: 'SASS', name: 'SASS' },
        ],
      },
      {
        id: '4',
        name: 'Skulls & Bones',
        image: skulls,
        description: 'A fun Tic Tac Toe game. Built with JS, JQuery, HTML nad CSS',
        links: {
          githubRepo: 'https://github.com/phfa26/ticTacToeGame/',
          liveDeployment: 'https://phfa26.github.io/ticTacToeGame/',
        },
        languages: [
          { id: 'JS', name: 'JS' },
          { id: 'JQuery', name: 'JQuery' },
          { id: 'CSS', name: 'CSS' },
          { id: 'HTML', name: 'HTML' },
        ],
      },
      {
        id: '5',
        name: 'Expenses Tracker',
        image: expenses,
        description: 'A clear desingned, functional and simple to use daily expenses tracker.',
        links: {
          githubRepo: 'https://github.com/phfa26/ExpensesTrackerApp',
          liveDeployment: 'https://my-expenses-tracker-project.herokuapp.com/',
        },
        languages: [
          { id: 'Rails', name: 'Ruby-on-Rails' },
          { id: 'JS', name: 'JS' },
          { id: 'Bootstrap', name: 'Bootstrap' },
          { id: 'Heroku', name: 'Heroku' },
        ],
      },
      {
        id: '6',
        name: 'Guess A Number - Mobile',
        image: guessANumber,
        description: 'A simple guess a number mobile app. Uses React Native to run on iOS and Android devices.',
        links: {
          githubRepo: 'https://github.com/phfa26/ReactNativeCourse/tree/master/guess-a-number-app',
          liveDeployment: false,
        },
        languages: [
          { id: 'ReactNative', name: 'React Native' },
          { id: 'Bootstrap', name: 'Expo' },
          { id: 'iOS', name: 'iOS' },
          { id: 'android', name: 'android' },
        ],
      },
      {
        id: '7',
        name: 'UHost (fictional business)',
        image: UHost,
        description: 'A website that demonstrate the use modern CSS features, such as Flexbox and CSS grid',
        links: {
          githubRepo: 'https://github.com/phfa26/CSS-the-complete-guide__course',
          liveDeployment: 'https://phfa26.github.io/CSS-the-complete-guide__course/',
        },
        languages: [
          { id: 'JS', name: 'JS' },
          { id: 'CSS', name: 'CSS' },
          { id: 'Flexbox', name: 'Flexbox' },
          { id: 'Grid', name: 'Grid' },
        ],
      },
      {
        id: '8',
        name: '"Givng"',
        image: givng,
        description:
          'A simple Gift Lists manager. Create lists of presents and split a given budget among people within those lists.',
        links: {
          githubRepo: 'https://github.com/phfa26/project-2',
          liveDeployment: 'https://givng.herokuapp.com/',
        },
        languages: [
          { id: 'React', name: 'React' },
          { id: 'Rails', name: 'Ruby-on-Rails' },
          { id: 'Postgres', name: 'PostgresSQL  ' },
          { id: 'Heroku', name: 'Heroku' },
          { id: 'Bestbuy', name: 'BestBuy-API' },
        ],
      },
    ],
  },
  {
    technology: 'WordPress',
    projects: [
      {
        id: '1',
        name: 'Alpha Athletics and Aesthetics',
        image: shawn,
        description: `Australia's #1 Personal Trainer now has the best website as well! A custom built website with further support for e-commerce and customer base magement.`,
        links: {
          githubRepo: false,
          liveDeployment: 'https://alphaathletics.com.au/',
        },
        languages: [
          { id: 'WP', name: 'WordPress' },
          { id: 'WPBakery', name: 'WPBakery' },
          { id: 'WooCommerce', name: 'WooCommerce' },
          { id: 'CustomThemes', name: 'Custom Themes' },
          { id: 'MailChimp', name: 'MailChimp' },
          { id: 'Email', name: 'Email Services' },
          { id: 'Google', name: 'Google Analytics' },
        ],
      },
      {
        id: '2',
        name: 'IoNV - Institute of non-violence',
        image: ionv,
        description:
          'A complete and detailed website that uses its own custom theme, built by me. Uses Wordpress as CMS and features Blogs, Audio Players, Video Players, and much more.',
        links: {
          githubRepo: false,
          liveDeployment: 'https://ionv.com.au/',
        },
        languages: [
          { id: 'WP', name: 'WordPress' },
          { id: 'Themify', name: 'Themify' },
          { id: 'CustomThemes', name: 'Custom Themes' },
          { id: 'MailChimp', name: 'MailChimp' },
          { id: 'Google', name: 'Google Analytics' },
        ],
      },
      {
        id: '3',
        name: 'Upcycle Emporium',
        image: upcycle,
        description:
          'A fully functional e-commerce solution built on WordPress and WooCommerce, the most used e-commerce stack in the world.',
        links: {
          githubRepo: false,
          liveDeployment: 'https://upcycleemporium.com.au/',
        },
        languages: [
          { id: 'WP', name: 'WordPress' },
          { id: 'Themify', name: 'Themify' },
          { id: 'WooCommerce', name: 'WooCommerce' },
          { id: 'AfterPay', name: 'AfterPay' },
        ],
      },
      {
        id: '4',
        name: 'World On Wheelz',
        image: wow,
        description:
          'Accessibility is important for Alex. This e-commerce solution built on WordPress and WooCommerce, the most used e-commerce stack in the world, offers an easy and straightforward accessibility toolkit for people that have low vision.',
        links: {
          githubRepo: false,
          liveDeployment: 'https://www.worldonwheelz.com.au/',
        },
        languages: [
          { id: 'WP', name: 'WordPress' },
          { id: 'Themify', name: 'Themify' },
          { id: 'WooCommerce', name: 'WooCommerce' },
          { id: 'accessibility', name: 'Accessibility tools' },
        ],
      },
      {
        id: '5',
        name: 'Luster Painting',
        image: luster,
        description:
          'A business webpage that uses its own custom theme, built by me. If you are in Metro Vancouver and need some work done, make sure to contact Yassin, a Syrian Refugee entrepreneur.',
        links: {
          githubRepo: false,
          liveDeployment: 'https://www.lusterpainting.com/',
        },
        languages: [
          { id: 'WP', name: 'WordPress' },
          { id: 'Themify', name: 'Themify' },
          { id: 'CustomThemes', name: 'Custom Themes' },
        ],
      },
    ],
  },
];

export default ProjectList;
