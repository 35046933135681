import React, { useContext } from 'react';
import AnchorLink from 'react-anchor-link-smooth-scroll';
import { ThemeContext } from 'providers/ThemeProvider';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/dev.png';
import { Wrapper, SkillsWrapper, Details, Thumbnail } from './styles';

export const Skills = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper id="about">
      <SkillsWrapper as={Container}>
        <Thumbnail>
          <img src={dev} alt="I’m Paulo and I’m a Software Engineer!" />
        </Thumbnail>
        <Details theme={theme}>
          <h1>More about me</h1>
          <p>
            As an eternal learner, curiosity (and coffee) is what fuels me.<br></br>
            Always up for a challenge.I don't think anything is impossible, just not done yet!
            <br></br>
            My hobbies are Walks, Camping, anything in the bush, and heavy metal, of course.
            <br></br>
            Get in touch :) Let's talk about this Developer Role you have in your company!
          </p>
          <Button as={AnchorLink} href="#contact">
            Get in touch
          </Button>
        </Details>
      </SkillsWrapper>
    </Wrapper>
  );
};
