import React, { useContext } from 'react';
import { ThemeContext } from 'providers/ThemeProvider';
import { Header } from 'components/theme';
import { Container, Button } from 'components/common';
import dev from 'assets/illustrations/dev8.png';
import resume from 'assets/downloads/ResumePaulo.pdf';
import { Wrapper, IntroWrapper, Details, Thumbnail, ButtonContainer } from './styles';

export const Intro = () => {
  const { theme } = useContext(ThemeContext);

  return (
    <Wrapper>
      <Header />
      <IntroWrapper as={Container}>
        <Details theme={theme}>
          <h1>Hi There!</h1>
          <h4>I’m Paulo and I’m a Software Engineer!</h4>
          <ButtonContainer>
            <Button as="a" href={resume} target="_resume" rel="noopener noreferrer">
              Download resume
            </Button>
          </ButtonContainer>
          <h5>TypeScript, React, JavaScript, Node, Rails, and more...</h5>
        </Details>
        <Thumbnail>
          <img src={dev} alt="I’m Paulo and I’m a software engineer!" />
        </Thumbnail>
      </IntroWrapper>
    </Wrapper>
  );
};
