import React from 'react';

const Github = ({ width = 16, height = 16, color = '#000' }) => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width={width} height={height}>
    <path
      fill={color}
      fillRule="evenodd"
      d="M8 0C6.94943 0 5.90914 0.212064 4.93853 0.624083C3.96793 1.0361 3.08601 1.64001 2.34315 2.40132C0.842855 3.93886 0 6.02421 0 8.19862C0 11.8224 2.296 14.8969 5.472 15.9873C5.872 16.0529 6 15.7987 6 15.5774V14.1918C3.784 14.6837 3.312 13.0932 3.312 13.0932C2.944 12.1421 2.424 11.888 2.424 11.888C1.696 11.3797 2.48 11.3961 2.48 11.3961C3.28 11.4535 3.704 12.2405 3.704 12.2405C4.4 13.4867 5.576 13.1178 6.032 12.921C6.104 12.3881 6.312 12.0274 6.536 11.8224C4.76 11.6174 2.896 10.9124 2.896 7.78869C2.896 6.87864 3.2 6.14896 3.72 5.56686C3.64 5.36189 3.36 4.50924 3.8 3.40243C3.8 3.40243 4.472 3.18106 6 4.23868C6.632 4.05831 7.32 3.96813 8 3.96813C8.68 3.96813 9.368 4.05831 10 4.23868C11.528 3.18106 12.2 3.40243 12.2 3.40243C12.64 4.50924 12.36 5.36189 12.28 5.56686C12.8 6.14896 13.104 6.87864 13.104 7.78869C13.104 10.9206 11.232 11.6092 9.448 11.8142C9.736 12.0684 10 12.5685 10 13.3309V15.5774C10 15.7987 10.128 16.0611 10.536 15.9873C13.712 14.8887 16 11.8224 16 8.19862C16 7.12196 15.7931 6.05584 15.391 5.06114C14.989 4.06644 14.3997 3.16263 13.6569 2.40132C12.914 1.64001 12.0321 1.0361 11.0615 0.624083C10.0909 0.212064 9.05058 0 8 0V0Z"
    ></path>
  </svg>
);

export default Github;
